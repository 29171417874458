import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { SearchFiltersFacetQuery } from '@interfaces/search-filters-facet-query.model';
import { Injectable } from '@angular/core';
import { WindowService } from '@services/window.service';
import { SearchFilterV2 } from '@interfaces/search-filter-v2.model';

@Injectable({
  providedIn: 'root',
})
export class SearchFacetParamsService {
  constructor(private windowService: WindowService) {}

  public calculate(
    initialParams: HttpParams,
    facetsRequest: boolean,
    filtersFromStore?: SearchFilterV2[]
  ): HttpParams {
    if (!filtersFromStore) {
      return initialParams;
    }
    return filtersFromStore?.reduce(
      (params: HttpParams, filter: SearchFilterV2) => {
        if (facetsRequest) {
          if (filter.facet && !filter.disabled) {
            params = this.addFacetParams(filter, params);
          }
        }

        return params;
      },
      initialParams
    );
  }

  /**
   *  @description Replace facet[tiers:key]=value with facet[tiers_facetable:key]=value for API to handle tiers faceting properly.
   *  @param params: HttpParams
   *  @returns HttpParams
   */
  public updateTiersFacetableParam(params: HttpParams): HttpParams {
    params.keys().forEach((key: string) => {
      if (key.indexOf('facet[tiers:') !== -1) {
        const newKey = key.replace('facet[tiers:', 'facet[tiers_facetable:');
        params = params.set(newKey, params.get(key));
        params = params.delete(key);
      }
    });
    return params;
  }

  public updateMultiSelectFilterParams(
    params: HttpParams,
    searchFilters: SearchFilterV2[]
  ): HttpParams {
    searchFilters.forEach(({ type, facet }) => {
      const typeAndConditional = type?.split('#');
      if (params.has(facet) && typeAndConditional?.[0] === 'multi') {
        // Multi select values use | as separator
        const value = params.get(facet).split('|');
        if (value.length > 1) {
          params = params.delete(facet);
          params =
            typeAndConditional[1] === 'or'
              ? this.buildOrMultiFacetType(facet, value, params)
              : this.buildAndMultiFacetType(facet, value, params);
        }
      }
    });
    return params;
  }

  /**
   *  @description Replace facet[tiers_facetable:key] property with facet[tiers:key] to compensate
   *  for the way the API handles tiers facet indexing.
   *  @param facets: any
   *  @returns any
   */
  public updateTiersFacetableProperty(facets: any): any {
    _.each(facets, (value: any, key: string) => {
      if (key.indexOf('age_limitations:') !== -1) {
        if (typeof facets['age_limitations'] === 'undefined') {
          facets['age_limitations'] = [];
        }
        if (value?.length) {
          const newName = key.split(':')[1];
          facets['age_limitations'].push({
            name: newName,
            value: newName,
          });
        }
        delete facets[key];
      }
      if (key.indexOf('tiers_facetable:') !== -1) {
        const newKey = key.replace('tiers_facetable:', 'tiers:');
        facets[newKey] = value;
        delete facets[key];
      }
    });
    return facets;
  }

  /**
   *  @description Group facet request params into one request
   *  @param initialParams: HttpParams
   *  @param searchFilters: SearchFilterV2[]
   *  @returns HttpParams
   */
  public getFacetRequestParams(
    initialParams: HttpParams,
    searchFilters: SearchFilterV2[]
  ): HttpParams {
    initialParams = this.removeFacetParams(initialParams);
    let combinedParams: HttpParams = initialParams;

    searchFilters.forEach((filter: SearchFilterV2) => {
      if (filter.facet && !filter.disabled) {
        combinedParams = this.addFacetParams(filter, combinedParams);
      }
    });
    return combinedParams;
  }

  /**
   *  @description Replace "tiers:code" param with "restrict[tiers:code][]"
   *  to compensate for the way the API handles tiers
   *  @param params: HttpParams
   *  @param searchFilters: SearchFilterV2[]
   *  @returns HttpParams
   */
  public handleSingleTiersParam(
    params: HttpParams,
    searchFilters: SearchFilterV2[]
  ): HttpParams {
    const tierFacet = searchFilters.find((filter) =>
      filter.facet.includes('tiers')
    )?.facet;
    if (params.has(tierFacet)) {
      params = params.append(`restrict[${tierFacet}][]`, params.get(tierFacet));
      params = params.delete(tierFacet);
    }
    return params;
  }

  private addFacetParams(
    filter: SearchFilterV2,
    params: HttpParams
  ): HttpParams {
    params = _.clone(params);
    const facetQueries = filter.facet_queries;
    if (facetQueries) {
      params = this.addFacetParamsForQueries(params, facetQueries);
    } else if (filter.facet && !filter.disabled) {
      params = params.set(`facet[${filter.facet}]`, 'true');
    } else {
      return null;
    }
    // search specialties searches always include the search_specialty_id
    const url_pieces = this.windowService['location'].href.split('/');
    if (
      url_pieces[4] === 'search_specialties' &&
      !params.has('search_specialty_id')
    ) {
      params = params.set('search_specialty_id', url_pieces[5]);
    }
    return params;
  }

  private addFacetParamsForQueries(
    params: HttpParams,
    facetQueries: SearchFiltersFacetQuery[]
  ): HttpParams {
    _.each(facetQueries, (fq: SearchFiltersFacetQuery) => {
      params = params.set(`facet[${fq.facetQuery || fq['string']}]`, fq.value);
    });

    return params;
  }

  private removeFacetParams(params: HttpParams): HttpParams {
    params.keys().forEach((key: string) => {
      if (key.indexOf('facet[') !== -1) {
        params = params.delete(key);
      }
    });
    return params;
  }

  private buildOrMultiFacetType(
    facet: string,
    values: string[],
    params: HttpParams
  ): HttpParams {
    values.forEach((value) => {
      params = params.append(`match[${facet}][]`, value);
    });
    return params;
  }

  private buildAndMultiFacetType(
    facet: string,
    values: string[],
    params: HttpParams
  ): HttpParams {
    values.forEach((value) => {
      params = params.append(`$and[][${facet}][$and][]`, value);
    });
    return params;
  }
}
